<template>
  <div class="layout-footer">
    <img alt="Logo" :src="footerImage()" height="20" />
    <!-- <span class="font-medium ml-2">POboxX</span> -->
    <span class="font-medium ml-2">&copy; {{ year }}</span>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "AppFooter",
  data() {
    return {
      year: moment().format("YYYY"),
    };
  },
  methods: {
    footerImage() {
      return this.$appState.darkTheme
        ? "images/POBoxXLogoDark.png"
        : "images/POBoxXLogoLight.png";
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>
