import api from "./api";
import TokenService from "./token.service";

class AuthService {
    login() {
        return api
            .post("/Authenticate/LogInAdminUser?username="+this.username+"&password="+this.password, {
                
            })
            .then((response) => {
                if (response.data.token) {
                    TokenService.setUser(response.data);
                }
                return response.data;
            });
    }

    companylogin() {
        return api
            .post("/CompanyUser/LogInCompanyUser?username="+this.username+"&password="+this.password, {
                
            })
            .then((response) => {
                if (response.data.token) {
                    TokenService.setUser(response.data);
                }
                return response.data;
            });
    }

    logout() {
        localStorage.removeItem("user"); //remove the token from local storage;
        // return api
        //     .post("/admin/signout", {});

    }


    // register({ user }) {
    //     return api.post("/providers/account/signup", {
    //         firstname: user.firstname,
    //         middlename: user.middlename,
    //         lastname: user.lastname,
    //         email: user.email,
    //         mobile_number: user.mobile_number,
    //         password: user.password,
    //         password_confirmation: user.password_confirmation,
    //         usertype: user.usertype,
    //     });
    // }
}

export default new AuthService();