<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
      <AppFooter />
    </div>

    <!-- <AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" /> -->
    <transition name="layout-mask">
      <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
    </transition>
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
// import AppConfig from "./AppConfig.vue";
import AppFooter from "./AppFooter.vue";
import api from "./services/api";
import { mapState } from "vuex";

export default {
  emits: ["change-theme"],
  data() {
    return {
      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      updatePermissions: [],
      rolePermissions: [],
      menu: [],
      preMenu: [
        {
          label: "HOME",
          icon: "pi pi-fw pi-map-marker",
          items: [{ label: "Dashboard", icon: "pi pi-fw pi-home", to: "/dashboard" }],
        },
        {
          label: "CABINET",
          icon: "pi pi-fw pi-server",
          items: [
            {
              label: "Cabinets",
              icon: "pi pi-fw pi-server",
              items: [
                {
                  label: "Location",
                  icon: "pi pi-fw pi-map-marker",
                  items: [
                    {
                      label: "Active",
                      icon: "pi pi-fw pi-map-marker",
                      to: "/cabinet-location",
                    },
                    {
                      label: "Deactivated",
                      icon: "pi pi-fw pi-map-marker",
                      to: "/inactivated-cabinet-location",
                    },
                  ],
                },
                {
                  label: "Maintain Cabinet",
                  icon: "pi pi-fw pi-server",
                  to: "/cabinet",
                },
                {
                  label: "Company Cabinet",
                  icon: "pi pi-fw pi-server",
                  to: "/company_cabinet",
                },
                {
                  label: "Cabinet Type",
                  icon: "pi pi-fw pi-server",
                  to: "/cabinet-type",
                },
              ],
            },
          ],
        },

        {
          label: "PRICING MATRIX",
          icon: "pi pi-fw pi-money-bill",
          items: [
            {
              label: "Pricing Configurations",
              icon: "pi pi-fw pi-money-bill",
              items: [
                {
                  label: "Pricing Plan",
                  icon: "pi pi-money-bill",
                  items: [
                    {
                      label: "Active",
                      icon: "pi pi-money-bill",
                      to: "/pricing-type",
                    },
                    {
                      label: "Deactivated",
                      icon: "pi pi-money-bill",
                      to: "/deactivated-pricing-type",
                    },
                  ],
                },
                {
                  label: "Price Matrix Config ",
                  icon: "pi pi-money-bill",
                  items: [
                    {
                      label: "Active",
                      icon: "pi pi-money-bill",
                      to: "/price-matrix-config",
                    },
                    {
                      label: "Deactivated",
                      icon: "pi pi-money-bill",
                      to: "/deactivated-price-matrix-config",
                    },
                  ],
                },
                {
                  label: "Price And Charging",
                  icon: "pi pi-money-bill",
                  items: [
                    {
                      label: "Active",
                      icon: "pi pi-money-bill",
                      to: "/price-and-charging",
                    },
                    {
                      label: "Deactivated",
                      icon: "pi pi-money-bill",
                      to: "/deactivated-price-and-charging",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          label: "LOCKER",
          icon: "pi pi-fw pi-map-marker",
          items: [
            {
              label: "Lockers",
              icon: "pi pi-fw pi-th-large",
              items: [
                {
                  label: "Locker Type",
                  icon: "pi pi-fw pi-th-large",
                  to: "/locker-type",
                },
                {
                  label: "Locker Zone",
                  icon: "pi pi-fw pi-th-large",
                  to: "/locker-zone",
                  // items: [
                  //   {
                  //     label: "Active",
                  //     icon: "pi pi-fw pi-th-large",
                  //     to: "/locker-zone",
                  //   },
                  //   {
                  //     label: "Deactivated",
                  //     icon: "pi pi-fw pi-th-large",
                  //     to: "/deac-locker-zone",
                  //   },
                  // ],
                },
                {
                  label: "Maintain Locker",
                  icon: "pi pi-fw pi-table",
                  to: "/available-locker-detail",
                },
                {
                  label: "Locker Status",
                  icon: "pi pi-fw pi-table",
                  to: "/available-locker-status",
                },
                {
                  label: "Booked Locker Update",
                  icon: "pi pi-fw pi-table",
                  to: "/locker-update",
                },
              ],
            },
            {
              label: "Lockers Re-assignment",
              icon: "pi pi-fw pi-th-large",
              items: [
                {
                  label: "Re-assign Locker",
                  icon: "pi pi-fw pi-th-large",
                  to: "/locker-reassign-booking",
                },
                {
                  label: "Re-assignment History",
                  icon: "pi pi-fw pi-th-large",
                  to: "/locker-reassign-history",
                },
              ],
            },
          ],
        },
        {
          label: "ROLES/PERMISSIONS",
          icon: "pi pi-fw pi-map-marker",
          items: [
            {
              label: " Role",
              icon: "pi pi-fw pi-user-plus",
              items: [
                {
                  label: "Roles",
                  icon: "pi pi-fw pi-user-plus",
                  items: [
                    {
                      label: "Roles",
                      icon: "pi pi-fw pi-user-plus",
                      to: "/roles",
                    },
                    {
                      label: "Deactivated Roles",
                      icon: "pi pi-fw pi-user-plus",
                      to: "/deactivated-roles",
                    },
                  ],
                },
                {
                  label: "Assigned User Roles",
                  icon: "pi pi-fw pi-user-plus",
                  items: [
                    {
                      label: "User's Roles",
                      icon: "pi pi-fw pi-user-plus",
                      to: "/user-roles",
                    },
                    {
                      label: "Deactivated User's Roles",
                      icon: "pi pi-fw pi-user-plus",
                      to: "/deactivated-user-roles",
                    },
                  ],
                },
              ],
            },
            {
              label: " Permission",
              icon: "pi pi-fw pi-user-plus",
              items: [
                {
                  label: "Permissions",
                  icon: "pi pi-fw pi-user-plus",
                  to: "/permissions",
                },
                {
                  label: "Role Permissions",
                  icon: "pi pi-fw pi-user-plus",
                  to: "/role-permissions",
                },
              ],
            },
          ],
        },
        {
          label: "USERS",
          icon: "pi pi-fw pi-map-marker",
          items: [
            {
              label: " Users",
              icon: "pi pi-fw pi-user-plus",
              items: [
                // {
                //   label: "Add Admin Users",
                //   icon: "pi pi-fw pi-user-plus",
                //   to: "/add-user",
                // },
                {
                  label: "Users",
                  icon: "pi pi-fw pi-user-plus",
                  to: "/admin-users",
                },
                {
                  label: "Company Users",
                  icon: "pi pi-fw pi-th-large",
                  items: [
                    {
                      label: "Active",
                      icon: "pi pi-fw pi-user-plus",
                      to: "/company-user",
                    },
                    {
                      label: "Deactivated",
                      icon: "pi pi-fw pi-user-plus",
                      to: "/deac-company-user",
                    },
                  ],
                },
                {
                  label: "App Users",
                  icon: "pi pi-fw pi-mobile",
                  to: "/app-users",
                },
              ],
            },
            // {
            //   label: "Add Company Users",
            //   icon: "pi pi-fw pi-user-plus",
            //   to: "/add-company-user",
            // },
            // { label: "Add App Users", icon: "pi pi-fw pi-mobile", to: "/add-app-user" },
          ],
        },

        {
          label: "FEEDBACK",
          icon: "pi pi-fw pi-money-bill",
          items: [
            {
              label: "Feedback/Survey",
              icon: "pi pi-fw pi-replay",
              items: [
                {
                  label: "Mobile App",
                  icon: "pi pi-fw pi-check",
                  to: "/app-answers",
                },
              ],
            },
          ],
        },
        {
          label: "FRANCHISEE",
          icon: "pi pi-fw pi-map-marker",
          items: [
            {
              label: "Franchisee",
              icon: "pi pi-fw pi-user-plus",
              items: [
                {
                  label: "Franchisee",
                  icon: "pi pi-fw pi-user-plus",
                  to: "/franchise",
                },
                {
                  label: "Feedbacks/Surveys",
                  icon: "pi pi-fw pi-replay",
                  items: [
                    {
                      label: "Franchisee Questions",
                      icon: "pi pi-fw pi-question",
                      to: "/questions",
                    },
                    {
                      label: "Franchisee Answers",
                      icon: "pi pi-fw pi-check",
                      to: "/answers",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          label: "PROFILE",
          icon: "pi pi-fw pi-map-marker",
          items: [
            {
              label: "Head Office Profile",
              icon: "pi pi-fw pi-user-plus",
              to: "/head-office-profile",
            },
            // {label: 'Add App Users', icon: 'pi pi-fw pi-mobile', to: '/add-app-user'},
          ],
        },
        {
          label: "PROMO AND ANNOUNCEMENTS",
          icon: "pi pi-fw pi-comments",

          items: [
            {
              label: "Promo/Announcement",
              icon: "pi pi-fw pi-comments",

              items: [
                {
                  label: "Announcement Type",
                  icon: "pi pi-fw pi-comments",
                  to: "/announce-type",
                },
                {
                  label: "Promo/Announcement",
                  icon: "pi pi-fw pi-comments",
                  to: "/announce-promo",
                },
              ],
            },
            {
              label: "Screen Savers",
              icon: "pi pi-fw pi-comments",
              to: "/promo-discount",
            },
            {
              label: "Ads",
              icon: "pi pi-fw pi-comments",
              to: "/ads",
            },
          ],
        },
        {
          label: "MAINTENANCE",
          icon: "pi pi-fw pi-cog",
          items: [
            {
              label: "Maintenance",
              icon: "pi pi-fw pi-cog",
              items: [
                {
                  label: "Reason Type",
                  icon: "pi pi-fw pi-sliders-h",
                  items: [
                    {
                      label: "Reason Type",
                      icon: "pi pi-fw pi-sliders-h",
                      to: "/reason-type",
                    },
                    {
                      label: "Deactivated Reason Type",
                      icon: "pi pi-fw pi-sliders-h",
                      to: "/deac-reason-type",
                    },
                  ],
                },
                {
                  label: "Inspection/Testing",
                  icon: "pi pi-fw pi-exclamation-circle",
                  to: "/inspection-testing",
                },
                {
                  label: "Cleanliness",
                  icon: "pi pi-fw pi-exclamation-circle",
                  to: "/cleanliness",
                },
              ],
            },
            {
              label: "Application Message",
              icon: "pi pi-fw pi-sliders-h",
              items: [
                {
                  label: "App Message Setting",
                  icon: "pi pi-fw pi-cog",
                  to: "/app-message-settings",
                },
              ],
            },
            {
              label: "Application Settings",
              icon: "pi pi-fw pi-sliders-h",
              items: [
                {
                  label: "App Settings",
                  icon: "pi pi-fw pi-cog",
                  to: "/app-settings",
                },
              ],
            },
          ],
        },
        {
          label: "REPORTS",
          icon: "pi pi-book",
          items: [
            {
              label: "Reports",
              icon: "pi pi-book",
              items: [
                {
                  label: "Transactions",
                  icon: "pi pi-fw pi-briefcase",
                  to: "/transaction-report",
                },
                {
                  label: "Original",
                  icon: "pi pi-fw pi-briefcase",
                  to: "/original-report",
                },
                {
                  label: "On-Demand Bookings",
                  icon: "pi pi-fw pi-table",
                  to: "/on-demand-bookings",
                },
                {
                  label: "Single Access",
                  icon: "pi pi-fw pi-table",
                  to: "/single-access-bookings",
                },
                {
                  label: "Multi Access",
                  icon: "pi pi-fw pi-table",
                  to: "/multi-access-bookings",
                },

                {
                  label: "Top Up",
                  icon: "pi pi-fw pi-wallet",
                  to: "/vault",
                },
                {
                  label: "Payment Report",
                  icon: "pi pi-book",
                  to: "/payment-report",
                },
                {
                  label: "Wallet Adjustment Report",
                  icon: "pi pi-book",
                  to: "/wallet-transactions-report",
                },
                {
                  label: "Summary of Transaction",
                  icon: "pi pi-book",
                  to: "/customer-summary-of-transaction",
                },
              ],
            },
          ],
        },
        {
          label: "WALLET MANAGEMENT",
          icon: "pi pi-book",
          items: [
            {
              label: "Manual Wallet Adjustment",
              icon: "pi pi-fw  pi-fw pi-credit-card",
              to: "/wallet-adjustment",
            },
          ],
        },
        {
          label: "TERMS/CONDITIONS",
          icon: "pi pi-book",
          items: [
            {
              label: "Terms & Conditions",
              icon: "pi pi-fw  pi-fw pi-book",
              to: "/terms-conditions",
            },

            {
              label: "Data Privacy Policy",
              icon: "pi pi-eye-slash",
              to: "/privacy-policy",
            },
          ],
        },
        {
          label: "FAQs",
          icon: "pi pi-book",
          items: [
            {
              label: "FAQs",
              icon: "pi pi-fw  pi-fw pi-book",
              to: "/faqs",
            },
            {
              label: "Contact Us",
              icon: "pi pi-fw  pi-fw pi-book",
              to: "/contact-us",
            },
          ],
        },
        {
          label: "LOGS",
          icon: "pi pi-book",
          items: [
            {
              label: "System Logs",
              icon: "pi pi-fw pi-book",
              to: "/logs",
            },
            {
              label: "Tablet Console Logs",
              icon: "pi pi-fw  pi-fw pi-book",
              to: "/tablet-logs",
            },
          ],
        },
      ],
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  created() {
    this.updateRolePermissions();
    this.getRolePermissions();
  },
  // mounted() {
  //   // this.permissions();

  //   // this.$store.access.dispatch("setUserPermissions");
  //   alert(this.accessState.state);
  // },

  methods: {
    updateRolePermissions() {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const roleId = userData.roleId[0];
      return (
        api
          .get("/Admin/GetRolePermissionsByRoleId?RoleId=" + roleId)
          // .then(res => res.data.json())
          .then((res) => {
            this.loading1 = false;
            // this.$toast.add({severity:'success', summary: 'SUCCESS' ,detail: res.data.message, life: 1500});
            this.updatePermissions = res.data.filter((isDeleted) => !isDeleted.isDeleted);
            localStorage.setItem(
              "menu",
              JSON.stringify(
                this.updatePermissions.map((permission) => permission.permissionName)
              )
            );
          })
      );
    },

    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    permissions(filters) {
      const filteredMenus = [];
      filters.forEach((filterValue) => {
        return filteredMenus.push(
          ...this.preMenu.filter((val) => val.label.includes(filterValue))
        );
      });
      this.menu1 = filteredMenus;
      const commonElements = this.preMenu.filter((element) =>
        this.menu1.includes(element)
      );
      this.menu = commonElements;
      // alert(commonElements);
    },
    getRolePermissions() {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const roleId = userData.roleId[0];
      const newMenu = [];
      return (
        api
          .get("/Admin/GetRolePermissionsByRoleId?RoleId=" + roleId)
          // .then(res => res.data.json())
          .then((res) => {
            this.loading1 = false;
            // this.$toast.add({severity:'success', summary: 'SUCCESS' ,detail: res.data.message, life: 1500});
            res.data.forEach((item) => {
              // this.rolePermissions = JSON.stringify(item.permissionName);
              newMenu.push(item.permissionName);
            });
            this.rolePermissions = newMenu.filter(function (item, index, inputArray) {
              return inputArray.indexOf(item) == index;
            });
            this.permissions(this.rolePermissions);
          })
          .catch((error) => {
            this.loading1 = false;
            if (error) {
              this.$toast.add({
                severity: "error",
                summary: "ERROR",
                detail: "No data found",
                life: 3000,
              });
            }
            // throws(error);
            console.log(error);
          })
      );
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },
  },
  computed: {
    ...mapState({
      accessState: (state) => state.access,
    }),
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme ? "images/logo-white.svg" : "images/logo.svg";
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive) this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    // AppConfig: AppConfig,
    AppFooter: AppFooter,
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
