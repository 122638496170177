import axiosInstance from "./api";
import TokenService from "./token.service";


const setup = (store) => {
    axiosInstance.interceptors.request.use(
        (config) => {
            const token = TokenService.getLocalaccess_token();
            if (token) {
                config.headers["Authorization"] = 'Bearer ' + token; // for Spring Boot back-end
                // config.headers["x-access-token"] = token; // for Node.js Express back-end
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    // axiosInstance.interceptors.response.use(
    //     (res) => {
    //         return res;
    //     },
    //     async(err) => {
    //         const originalConfig = err.config;

    //         if (originalConfig.url !== "/Authenticate/LogInAdminUser?username="+this.username+"&password="+this.password && err.response) {
    //             // Access Token was expired
    //             if (err.response.status === 401 && !originalConfig._retry) {
    //                 originalConfig._retry = true;

    //                 try {
    //                     const rs = await axiosInstance.post("/account/refresh", {
    //                         refreshToken: TokenService.getLocalRefreshToken(),
    //                     });

    //                     const { token } = rs.data;

    //                     store.dispatch('auth/refreshToken', token);
    //                     TokenService.updateLocalaccess_token(token);

    //                     return axiosInstance(originalConfig);
    //                 } catch (_error) {
    //                     localStorage.removeItem("user");
    //                     localStorage.removeItem("userData");
    //                     return Promise.reject(_error);
    //                 }
    //             }
    //         }

    //         return Promise.reject(err);
    //     }
    // );
};

export default setup;