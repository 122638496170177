import { createRouter, createWebHashHistory } from "vue-router";
import App from "./App.vue";
import App1 from "./App1.vue";
// import { store } from "./store/access";

const routes = [
  {
    path: "/",
    name: "app",
    component: App,
    children: [
      {
        path: "",

        name: "/",
        component: () => import("./view/Dashboard.vue"),
        // component: () => import('./components/Dashboard.vue')
      },
      {
        path: "dashboard",
        meta: { admin: true, requiredPermissions: ["HOME"] },
        name: "dashboard",
        component: () => import("./view/Dashboard.vue"),
        // component: () => import('./components/Dashboard.vue')
      },
      //###Start Message###
      {
        path: "message",
        name: "message",
        meta: { admin: true },
        component: () => import("./view/Message/Message.vue"),
        // component: () => import('./components/Dashboard.vue')
      },
      //###End Message###

      //###Start Cabinets###
      {
        path: "cabinet-location",
        meta: { admin: true, requiredPermissions: ["HOME"] },
        name: "cabinet-location",
        component: () => import("./view/Cabinet/CabinetLocation.vue"),
      },
      {
        path: "inactivated-cabinet-location",
        meta: { admin: true, requiredPermissions: ["HOME"] },
        name: "inactivated-cabinet-location",
        component: () => import("./view/Cabinet/InactiveCabinetLocation.vue"),
      },

      {
        path: "cabinet",
        meta: { admin: true, requiredPermissions: ["CABINET"] },
        name: "cabinet",
        component: () => import("./view/Cabinet/Cabinet.vue"),
      },
      {
        path: "company_cabinet",
        meta: { admin: true, requiredPermissions: ["CABINET"] },
        name: "company_cabinet",
        component: () => import("./view/Cabinet/CompanyCabinet.vue"),
      },
      {
        path: "cabinet-type",
        meta: { admin: true, requiredPermissions: ["CABINET"] },
        name: "cabinet-type",
        component: () => import("./view/Cabinet/CabinetType.vue"),
      },

      //###End Cabinets###

      //###Start Pricing###
      {
        path: "pricing-type",
        meta: { admin: true, requiredPermissions: ["PRICING MATRIX"] },
        name: "pricing-type",
        component: () => import("./view/Pricing/PricingType.vue"),
      },
      {
        path: "deactivated-pricing-type",
        meta: { admin: true, requiredPermissions: ["PRICING MATRIX"] },
        name: "deactivated-pricing-type",
        component: () => import("./view/Pricing/DeactivatedPricingType.vue"),
      },
      {
        path: "price-matrix-config",
        meta: { admin: true, requiredPermissions: ["PRICING MATRIX"] },
        name: "price-matrix-config",
        component: () => import("./view/Pricing/PriceMatrixConfig.vue"),
      },
      {
        path: "deactivated-price-matrix-config",
        meta: { admin: true, requiredPermissions: ["PRICING MATRIX"] },
        name: "deactivated-price-matrix-config",
        component: () => import("./view/Pricing/InactivePriceMatrixConfig.vue"),
      },
      {
        path: "price-and-charging",
        meta: { admin: true, requiredPermissions: ["PRICING MATRIX"] },
        name: "price-and-charging",
        component: () => import("./view/Pricing/PriceAndCharging.vue"),
      },
      {
        path: "deactivated-price-and-charging",
        meta: { admin: true, requiredPermissions: ["PRICING MATRIX"] },
        name: "deactivated-price-and-charging",
        component: () => import("./view/Pricing/DeacPriceAndCharging.vue"),
      },

      //###End Pricing###

      //###Start Lockers###
      {
        path: "locker-type",
        meta: { admin: true, requiredPermissions: ["LOCKER"] },
        name: "locker-type",
        component: () => import("./view/Cabinet/LockerType.vue"),
      },
      {
        path: "available-locker-detail",
        meta: { admin: true, requiredPermissions: ["LOCKER"] },
        name: "available-locker-detail",
        component: () => import("./view/Cabinet/AvailableLockerDetail.vue"),
      },
      {
        path: "available-locker-status",
        meta: { admin: true, requiredPermissions: ["LOCKER"] },
        name: "available-locker-status",
        component: () => import("./view/Cabinet/LockerStatus.vue"),
      },
      {
        path: "locker-zone",
        meta: { admin: true, requiredPermissions: ["LOCKER"] },
        name: "locker-zone",
        component: () => import("./view/Cabinet/LockerZone.vue"),
      },
      {
        path: "deac-locker-zone",
        meta: { admin: true, requiredPermissions: ["LOCKER"] },
        name: "deac-locker-zone",
        component: () => import("./view/Cabinet/DeacLockerZone.vue"),
      },
      {
        path: "locker-update",
        meta: { admin: true, requiredPermissions: ["LOCKER"] },
        name: "locker-update",
        component: () => import("./view/Cabinet/LockerUpdates.vue"),
      },
      {
        path: "locker-reassign-history",
        meta: { admin: true, requiredPermissions: ["LOCKER"] },
        name: "locker-reassign-history",
        component: () => import("./view/Cabinet/Reassignment.vue"),
      },
      {
        path: "locker-reassign-booking",
        meta: { admin: true, requiredPermissions: ["LOCKER"] },
        name: "locker-reassign-booking",
        component: () =>
          import("./view/Cabinet/ReassignBookingTransactions.vue"),
      },
      {
        path: "on-demand-bookings",
        meta: { admin: true, requiredPermissions: ["LOCKER"] },
        name: "on-demand-bookings",
        component: () =>
          import("./view/Report/OnDemandBooking.vue"),
      },
      //###End Lockers###

      //###Start Users###
      {
        path: "admin-users",
        meta: { admin: true, requiredPermissions: ["USERS"] },
        name: "admin-users",
        component: () => import("./view/Users/AdminUsers.vue"),
      },
      {
        path: "company-user",
        meta: { admin: true, requiredPermissions: ["USERS"] },
        name: "company-user",
        component: () => import("./view/Users/CompanyUser.vue"),
      },
      {
        path: "deac-company-user",
        meta: { admin: true, requiredPermissions: ["USERS"] },
        name: "deac-company-user",
        component: () => import("./view/Users/DeacCompanyUser.vue"),
      },
      {
        path: "app-users",
        meta: { admin: true, requiredPermissions: ["USERS"] },
        name: "app-users",
        component: () => import("./view/Users/getAppUsers.vue"),
      },
      {
        path: '/view-wallet-balance/:hash',
        meta: { admin: true, requiredPermissions: ["USERS"] },
        name: 'view-wallet-balance',
        component: () =>
            import ('./view/WalletManagement/ViewWallet.vue'),

    },
      {
        path: "add-user",
        meta: { admin: true, requiredPermissions: ["USERS"] },
        name: "add-user",
        component: () => import("./view/Users/Users.vue"),
      },
      {
        path: "add-app-user",
        meta: { admin: true, requiredPermissions: ["USERS"] },
        name: "add-app-user",
        component: () => import("./view/Users/AppUsers.vue"),
      },

      {
        path: "profile",
        meta: { admin: true },
        name: "profile",
        component: () => import("./view/Profile.vue"),
      },

      //###End Users###

      //###Start Roles and Permissions###
      {
        path: "roles",
        meta: { admin: true, requiredPermissions: ["ROLES/PERMISSIONS"] },
        name: "roles",
        component: () => import("./view/RolesAndPermissions/GetRoles.vue"),
      },
      {
        path: "deactivated-roles",
        meta: { admin: true, requiredPermissions: ["ROLES/PERMISSIONS"] },
        name: "deactivated-roles",
        component: () => import("./view/RolesAndPermissions/deactivatedRoles.vue"),
      },
      {
        path: "user-roles",
        meta: { admin: true, requiredPermissions: ["ROLES/PERMISSIONS"] },
        name: "user-roles",
        component: () => import("./view/RolesAndPermissions/GetUserRoles.vue"),
      },
      {
        path: "deactivated-user-roles",
        meta: { admin: true, requiredPermissions: ["ROLES/PERMISSIONS"] },
        name: "deactivated-user-roles",
        component: () => import("./view/RolesAndPermissions/DeactivatedUserRoles.vue"),
      },
      {
        path: "permissions",
        meta: { admin: true, requiredPermissions: ["ROLES/PERMISSIONS"] },
        name: "permissions",
        component: () =>
          import("./view/RolesAndPermissions/GetPermissions.vue"),
      },
      {
        path: "role-permissions",
        meta: { admin: true, requiredPermissions: ["ROLES/PERMISSIONS"] },
        name: "role-permissions",
        component: () =>
          import("./view/RolesAndPermissions/GetRolePermissions.vue"),
      },

      //###End Roles and Permissions###

      //###Start Company Profile###
      {
        path: "head-office-profile",
        meta: { admin: true, requiredPermissions: ["PROFILE"] },
        name: "head-office-profile",
        component: () => import("./view/CompanyProfile/HeadOffice.vue"),
      },

      //###End Company Profile###

      //###Start promo###
      {
        path: "announce-promo",
        meta: { admin: true, requiredPermissions: ["PROMO AND ANNOUNCEMENTS"] },
        name: "announce-promo",
        component: () => import("./view/PromoAnnounce/PromoAnnouncement.vue"),
      },
      {
        path: "announce-type",
        meta: { admin: true, requiredPermissions: ["PROMO AND ANNOUNCEMENTS"] },
        name: "announce-type",
        component: () => import("./view/PromoAnnounce/AnnouncementType.vue"),
      },
      {
        path: "ads",
        meta: { admin: true, requiredPermissions: ["PROMO AND ANNOUNCEMENTS"] },
        name: "ads",
        component: () => import("./view/PromoAnnounce/Ads.vue"),
      },
      {
        path: "promo-discount",
        meta: { admin: true, requiredPermissions: ["PROMO AND ANNOUNCEMENTS"] },
        name: "promo-discount",
        component: () => import("./view/PromoAnnounce/PromoDiscount.vue"),
      },
      //###End promo###

      //###Start Franchise###
      {
        path: "franchise",
        meta: { admin: true, requiredPermissions: ["FRANCHISEE"] },
        name: "franchise",
        component: () => import("./view/Franchise/Franchise.vue"),
      },
      {
        path: "questions",
        meta: { admin: true, requiredPermissions: ["FRANCHISEE"] },
        name: "questions",
        component: () => import("./view/Feedbacks/Questions.vue"),
      },

      {
        path: "answers",
        meta: { admin: true, requiredPermissions: ["FRANCHISEE"] },
        name: "answers",
        component: () => import("./view/Feedbacks/Answers.vue"),
      },

      //###End Franchise###

      //###Start Maintenance###
      {
        path: "reason-type",
        meta: { admin: true, requiredPermissions: ["MAINTENANCE"] },
        name: "reason-type",
        component: () => import("./view/Maintenance/ReasonType.vue"),
      },
      {
        path: "deac-reason-type",
        meta: { admin: true, requiredPermissions: ["MAINTENANCE"] },
        name: "deac-reason-type",
        component: () => import("./view/Maintenance/DeactivatedReasonType.vue"),
      },
      {
        path: "inspection-testing",
        name: "inspection-testing",
        meta: { admin: true, requiredPermissions: ["MAINTENANCE"] },
        component: () => import("./view/Maintenance/InspectionTesting.vue"),
      },
      {
        path: "cleanliness",
        name: "cleanliness",
        meta: { admin: true, requiredPermissions: ["MAINTENANCE"] },
        component: () => import("./view/Maintenance/Cleanliness.vue"),
      },
      {
        path: "app-message-settings",
        name: "app-message-settings",
        meta: { admin: true, requiredPermissions: ["MAINTENANCE"] },
        component: () => import("./view/Maintenance/AppMessageSettings.vue"),
      },
      {
        path: "app-settings",
        name: "app-settings",
        meta: { admin: true, requiredPermissions: ["MAINTENANCE"] },
        component: () => import("./view/Maintenance/AppSettings.vue"),
      },

      //###End Maintenance###

      //###Start Reports###
      {
        path: "transaction-report",
        meta: { admin: true, requiredPermissions: ["REPORTS"] },
        name: "transaction-report",
        component: () => import("./view/Report/BookingTransactions.vue"),
      },
      {
        path: "original-report",
        meta: { admin: true, requiredPermissions: ["REPORTS"] },
        name: "original-report",
        component: () => import("./view/Report/OriginalReport.vue"),
      },
      {
        path: "single-access-bookings",
        meta: { admin: true, requiredPermissions: ["REPORTS"] },
        name: "single-access-bookings",
        component: () => import("./view/Report/SingleAccess.vue"),
      },
      {
        path: "multi-access-bookings",
        meta: { admin: true, requiredPermissions: ["REPORTS"] },
        name: "multi-access-bookings",
        component: () => import("./view/Report/MultiAccess.vue"),
      },
      {
        path: "drop-off",
        meta: { admin: true },
        name: "drop-off",
        component: () => import("./view/Report/DropOff.vue"),
      },
      {
        path: "collected",
        meta: { admin: true },
        name: "collected",
        component: () => import("./view/Report/Collected.vue"),
      },
      {
        path: "bookings",
        meta: { admin: true },
        name: "bookings",
        component: () => import("./view/Report/Bookings.vue"),
      },
      {
        path: "vault",
        meta: { admin: true, requiredPermissions: ["REPORTS"] },
        name: "vault",
        component: () => import("./view/Report/Vault.vue"),
      },
      {
        path: "payment-report",
        meta: { admin: true, requiredPermissions: ["REPORTS"] },
        name: "payment-report",
        component: () => import("./view/Report/PaymentReport.vue"),
      },
      {
        path: "customer-summary-of-transaction",
        meta: { admin: true, requiredPermissions: ["REPORTS"] },
        name: "customer-summary-of-transaction",
        component: () => import("./view/Report/CustomerSummaryTransactions.vue"),
      },

      //###End Reports###


      //###Start Wallet management###
      {
        path: "wallet-adjustment",
        meta: { admin: true, requiredPermissions: ["WALLET MANAGEMENT"] },
        name: "wallet-adjustment",
        component: () => import("./view/WalletManagement/WalletAdjustment.vue"),
      },
      {
        path: "wallet-transactions-report",
        meta: { admin: true, requiredPermissions: ["WALLET MANAGEMENT"] },
        name: "wallet-transactions-report",
        component: () => import("./view/WalletManagement/WalletAdjustmentReport.vue"),
      },

      //###End Wallet management###

      //###Start Feedbacks###

      {
        path: "app-answers",
        meta: { admin: true, requiredPermissions: ["FEEDBACK"] },
        name: "app-answers",
        component: () => import("./view/Feedbacks/AppAnswers.vue"),
      },

      //###End Feedbacks###

      //###Start terms###

      {
        path: "terms-conditions",
        meta: { admin: true, requiredPermissions: ["TERMS/CONDITIONS"] },
        name: "terms-conditions",
        component: () => import("./view/Terms/TermsConditions.vue"),
      },
      {
        path: "privacy-policy",
        meta: { admin: true, requiredPermissions: ["TERMS/CONDITIONS"] },
        name: "privacy-policy",

        component: () => import("./view/Terms/PrivacyPolicy.vue"),
      },

      //###End terms###

      //###Start FAQs###

      {
        path: "faqs",
        meta: { admin: true, requiredPermissions: ["FAQs"] },
        name: "faqs",
        component: () => import("./view/FAQs/FAQs.vue"),
      },
      {
        path: "contact-us",
        meta: { admin: true, requiredPermissions: ["FAQs"] },
        name: "contact-us",
        component: () => import("./view/FAQs/ContactUs.vue"),
      },

      //###End FAQs###

       //###Start LOGS###

       {
        path: "tablet-logs",
        meta: { admin: true, requiredPermissions: ["LOGS"] },
        name: "tablet-logs",
        component: () => import("./view/Report/TabletLogs.vue"),
      },
      {
        path: "logs",
        meta: { admin: true, requiredPermissions: ["LOGS"] },
        name: "logs",
        component: () => import("./view/Report/Logs.vue"),
      },

      
      //###End LOGS###
    ],
  },

  //###################################################
  //             Company Panel Dashboard
  //##################################################

  {
    // path: '/company-dashboard',
    // name: 'company-dashboard',
    // component: () => import('./App1.vue')
    path: "/company-dashboard",
    name: "company-dashboard",
    component: App1,
    children: [
      {
        path: "/company-dashboard",

        name: "company-dashboard",
        // component: () => import('./view/Company/Dashboard.vue')
        component: () => import("./view/Company/CompanyDashboard.vue"),
        meta: { companyUser: true },
      },
      {
        path: "/company-inspection",
        name: "company-inspection",
        component: () => import("./view/Company/InspectionTesting.vue"),
        meta: { companyUser: true },
      },
      {
        path: "/company-cleanliness",
        name: "company-cleanliness",
        component: () => import("./view/Company/Cleanliness.vue"),
        meta: { companyUser: true },
      },
      {
        path: "/company-profile",
        name: "company-profile",
        component: () => import("./view/Company/Profile.vue"),
        meta: { companyUser: true },
      },
      {
        path: "/company-message",
        name: "company-message",
        component: () => import("./view/Company/Message.vue"),
        meta: { companyUser: true },
      },
      {
        path: "/company-add-cleanliness",
        name: "company-add-cleanliness",
        component: () => import("./view/Company/AddCleanliness.vue"),
        meta: { companyUser: true },
      },
      {
        path: "/company-feedbacks",
        name: "company-feedbacks",
        component: () => import("./view/Company/Feedbacks.vue"),
        meta: { companyUser: true },
      },
    ],
  },

  //###Start Callback urls###
  {
    path: "/success-authorized",
    name: "success-authorized",
    component: () => import("./pages/SuccessAuthorized.vue"),
  },
  {
    path: "/failed-authorized",
    name: "failed-authorized",
    component: () => import("./pages/FailedAuthorized.vue"),
  },
  {
    path: "/success-payment",
    name: "success-payment",
    component: () => import("./pages/SuccessPayment.vue"),
  },
  {
    path: "/failed-payment",
    name: "failed-payment",
    component: () => import("./pages/FailedPayment.vue"),
  },
  {
    path: "/cancelled-payment",
    name: "cancelled-payment",
    component: () => import("./pages/CancelledPayment.vue"),
  },
  //###End Callback urls###
  {
    path: "/login",
    name: "login",
    component: () => import("./pages/Login.vue"),
  },
  {
    path: "/landing",
    name: "landing",
    component: () => import("./pages/LandingDemo.vue"),
  },
  {
    path: "/error",
    name: "error",
    component: () => import("./pages/Error.vue"),
  },

  {
    path: "/access",
    name: "access",
    component: () => import("./pages/Access.vue"),
  },
  {
    path: "/view-terms-conditions",
    name: "view-terms-conditions",
    component: () => import("./pages/TermsConditions.vue"),
  },
  {
    path: "/view-privacy-policy",
    name: "view-privacy-policy",
    component: () => import("./pages/PrivacyPolicy.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notfound",
    component: () => import("./pages/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/register",
    "/home",
    "/success-authorized",
    "/failed-authorized",
    "/success-payment",
    "/failed-payment",
    "/notfound",
    "/cancelled-payment",
    "/view-terms-conditions",
    "/view-privacy-policy",
  ];
  const homePages = ["/", ""];
  const adminPage = homePages.includes(to.path);
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");
  const isUser = localStorage.getItem("companyUser");
  const admin = localStorage.getItem("admin");

  //roles permissions

  const requiredPermissions = to.meta.requiredPermissions;
  const menu = JSON.parse(localStorage.getItem("menu"));
  const permissions = menu;

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  }
  if (admin && loggedIn && to.meta.companyUser) {
    next("notfound");
  }
  if (isUser && loggedIn && to.meta.admin) {
    next("notfound");
  }
  if (adminPage && isUser && loggedIn) {
    next("/company-dashboard");
  }
  if (requiredPermissions) {
    const hasPermissions = requiredPermissions.every((permission) => {
      return permissions.includes(permission);
    });
    if (!hasPermissions) {
      next("/notfound");
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
