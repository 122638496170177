<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <img alt="Logo" :src="topbarImage()" style="width: 50%; height: auto" />
      <span>COMPANY</span>
    </router-link>
    <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
      <i class="pi pi-bars"></i>
    </button>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>
    <ul class="layout-topbar-menu hidden lg:flex origin-top">
      <li>
        <button class="p-link layout-topbar-button">
          <i class="pi pi-bell"></i>
          <span>Settings</span>
        </button>
      </li>
      <li>
        <!-- <i class="pi pi-envelope"></i> -->

        <button
          class="p-link layout-topbar-button"
          @click="this.$router.push('/company-message')"
        >
          <i class="pi pi-envelope"></i>
          <span>Settings</span>
        </button>
      </li>

      <li>
        <Menu ref="menu" :model="overlayMenuItems" :popup="true" />
        <button class="p-link layout-topbar-button" @click="toggleMenu">
          <i class="pi pi-user"></i>
          <span>Profile</span>
        </button>
        <span>{{ username }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: null,
      overlayMenuItems: [
        // {
        // 	label: 'Save',
        // 	icon: 'pi pi-save'
        // },
        // {
        // 	label: 'Update',
        // 	icon: 'pi pi-refresh'
        // },
        {
          label: "User Profile",
          icon: "pi pi-user",
          command: () => {
            this.$router.push("/company-profile");
          },
        },
        {
          separator: true,
        },
        {
          label: "Sign-Out",
          icon: "pi pi-sign-out",
          command: () => {
            localStorage.removeItem("user");
            localStorage.removeItem("userData");
            this.$store.dispatch("logout");
            this.$router.push("/login");
            localStorage.clear();
          },
        },
      ],
    };
  },
  created() {
    this.userProfile();
  },
  mounted() {},
  methods: {
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    onTopbarMenuToggle(event) {
      this.$emit("topbar-menu-toggle", event);
    },
    topbarImage() {
      return this.$appState.darkTheme
        ? "images/POBoxXLogoDark.svg"
        : "images/POBoxXLogoLight.svg";
    },
    toggleMenu(event) {
      this.$refs.menu.toggle(event);
    },
    userProfile() {
      const userData = JSON.parse(localStorage.getItem("userData"));
      this.username = userData[0].username;
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>
