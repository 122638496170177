import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://api.poboxx.ph/api',
  // baseURL: "https://api-stg.poboxx.ph/api",
  // baseURL: "http://13.229.147.123/api",
  // baseURL: "http://52.76.182.210/api",
  headers: {
    'Content-Type': 'application/json',
    // 'Cache-Control': 'no-cache',
    // 'Pragma': 'no-cache',
    // 'Expires': '0',
    // 54.151.232.165
  },
});

// https://api-stg.poboxx.ph/api/
export default instance;
